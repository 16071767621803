import { InputContainer, InputDiv, TitleH3 } from "./styles"

interface Props {
  title: string
  placeholder: string
  type: string
  icon: React.ReactNode
  required?: boolean
  span?: string
  value?: string
  name?: string
  onChange?: any
}

export const Input = ({
  title,
  placeholder,
  type,
  icon,
  required,
  span,
  value,
  onChange,
  name
}: Props) => {
  return (
    <InputContainer>
      <TitleH3>
        {title}
        {required ? (
          <span className="asterisco">*</span>
        ) : (
          <span className="optional">(Opcional)</span>
        )}
      </TitleH3>
      <InputDiv>
        <>
          {icon}
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </>
      </InputDiv>
      {span && <span className="span">{span}</span>}
    </InputContainer>
  )
}
