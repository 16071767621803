import { TitleH3, SelectContainer } from "./styles"
import Select from "react-select"

type props = {
  title: string,
  placeholder: string,
  options: any
  onChange: any
  name: string
  defaultValue: any
}

export default function SelectForm({ title, placeholder, options, onChange, name, defaultValue}: props) {
  return (
    <SelectContainer>
      <TitleH3>
        {title}
        <span className="asterisco">*</span>
      </TitleH3>
      <Select
        classNamePrefix="formSelect"
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        name={name}
        defaultValue={defaultValue}
      />
    </SelectContainer>
  )
}