import MainText from '../../../../components/elements/MainText'
import {
	GestaoEspecializadaContainer,
	Content,
	Image,
	RightSide
} from './style'

import Images from '../../../../assets/imagens/Grupo 5750 1.png'

export const GestaoEspecializada = () => {
	return (
		<GestaoEspecializadaContainer>
			<MainText
				pinkText={'Nossa equipe'}
				secondText='está pronta para atender às demandas do seu projeto'
			/>
			<Content>
				<Image src={Images} />
				<RightSide>
					<p>
						Somos especializados em fornecer equipes altamente qualificadas para
						atender às demandas de projetos dos nossos clientes. Oferecemos uma
						ampla variedade de profissionais, incluindo desenvolvedores de
						software, designers, analistas de dados e muitos outros. Com a nossa
						ajuda, nossos clientes podem escalar sua equipe rapidamente para
						atender às demandas do projeto, sem os custos associados à
						contratação e treinamento de novos funcionários.
					</p>
					<p>
						Além disso, nossa empresa se orgulha em ter uma ampla rede de
						profissionais com habilidades especializadas, permitindo que nossos
						clientes encontrem facilmente os talentos de quem precisam para
						concluir seus projetos. Nós garantimos que nossos clientes estejam
						satisfeitos com a qualidade dos talentos fornecidos, e nos
						comprometemos em fornecer soluções personalizadas para atender às
						necessidades específicas de cada projeto. Se você precisa de uma
						equipe flexível e altamente qualificada para atender ás demandas de
						seus projetos, entre em contato conosco e veja como podemos
						ajudá-lo!
					</p>
				</RightSide>
			</Content>
		</GestaoEspecializadaContainer>
	)
}
