import { Subtitle } from "../../../../components/elements/subtitle";
import { Title } from "../../../../components/elements/title";
import { NossasTecnologiasContainer } from "./styles";

import tecnologias from "../../../../assets/imagens/tecnologias.png";

export const NossasTecnologias = () => {
  return (
    <NossasTecnologiasContainer className="container">
      <Subtitle color="white">+ 30 TECNOLOGIAS</Subtitle>
      <div className="title">
        <Title color="white" size={35}>
          NOSSAS TECNOLOGIAS
        </Title>
      </div>
      <img src={tecnologias} alt="Nossas Tecnologias" className="tecnologias" />
      <Subtitle color="white">E MUITO MAIS</Subtitle>
    </NossasTecnologiasContainer>
  );
};
