import { useContext, useEffect, useState } from "react";
import { BsArrowReturnLeft, BsLinkedin, BsWhatsapp } from "react-icons/bs";
import { FiMail, FiPenTool, FiUser } from "react-icons/fi";
import logo from "../../../assets/iconeDuxcod3.png";
import Context from "../../../context";
import { Input } from "../../elements/Input";
import SelectForm from "../../elements/Select";
import ExitSvg from "../../../assets/imagens/exit.svg";

import {
  Button,
  ButtonReturn,
  InputCheckbox,
  Passo3Container,
  SidebarContainer,
  Subtitle,
  TitleH3,
  TextArea,
  ExitButton
} from "./styles";

interface Values {
  email: string;
  firstName: string;
  lastName: string;
  telephone: string;

  privacy: boolean;
  linkedin: string;
  atuation: number;
  level: number;
  history: string;
  message: string;
}

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const FormTalentos = ({ isOpen, setIsOpen }: Props) => {
  const [passo, setPasso] = useState(1);
  const [privacy, setPrivacy] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    telephone: "",
    file: "",
    privacy: false,
    atuation: 0,
    linkedin: "",
    level: 0,
    history: "",
    message: "",
    fileName: "Faça o upload do seu currículo"
  });
  const { setOnTermos, onTalentos, setTalentos } = useContext(Context);

  useEffect(() => {
    if (isOpen) {
      setTalentos(true);
    } else if (!isOpen) {
      setTalentos(false);
    }
  }, [isOpen]);

  const atuationSelect = [
    { value: 1, label: "FrontEnd" },
    { value: 2, label: "BackEnd" },
    { value: 3, label: "FullStack" },
    { value: 4, label: "DevOps" },
    { value: 5, label: "Design" }
  ];

  const levelSelect = [
    { value: 1, label: "Estagiário" },
    { value: 2, label: "Júnior" },
    { value: 3, label: "Pleno" },
    { value: 4, label: "Sênior" }
  ];

  async function sendApiForm(data: any) {
   
const body = new FormData();
body.append("usuarioTipo", "2")
body.append("Nome", data.firstName)
body.append("Sobrenome", data.lastName)
body.append("Email", data.email)
body.append("Telefone", data.telephone)
body.append("Linkedin", data.linkedin)
body.append("AreaAtuacao", data.atuation)
body.append("Senioridade", data.level)
body.append("Mensagem", data.message)
body.append("File", data.file)
body.append("TrajetoriaProfissional", data.history)
   
    const requestOptions = {
      method: "POST",
      body
    };

    const result = fetch(
      "https://backend.rockeit.com.br/api/Usuario/Talentos",
      requestOptions
    ).then((e) => {
      e.json();
  
    });

    return result;
  }
  const [cardFile, setCardFile] = useState();
  const handleUploadFile = (e: any) => setCardFile(e.target.files[0]);
  const Passo1 = (props: any) => {
    const [name, setName] = useState(formData.firstName);
    const [surname, setSurname] = useState(formData.lastName);
    const [email, setEmail] = useState(formData.email);
    const [phone, setPhone] = useState(formData.telephone);
    const [linkedin, setlinkedin] = useState(formData.linkedin);
    const [disableButton, setDisableButton] = useState(true);
    const [error, setError] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);

    //phone number validation
    useEffect(() => {
      if (phone.length !== 15) {
        setPhone(phone.replace(/[^\d]/g, ""));
        if (phone.length === 11) {
          setPhone(phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"));
        }
      }
    }, [phone]);

    //name validation
    useEffect(() => {
      setName(name.replace(/[0-9]/g, ""));
    }, [name]);

    //surname validation
    useEffect(() => {
      setSurname(surname.replace(/[0-9]/g, ""));
    }, [surname]);
    useEffect(() => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        setErrorEmail(true);
      }
    }, [email]);
    useEffect(() => {
      if (
        name !== "" &&
        surname !== "" &&
        email !== "" &&
        phone !== "" &&
        linkedin !== "" &&
        errorEmail !== true
      ) {
        setDisableButton(false);
      }
    }, [name, surname, email, phone, linkedin]);

    return (
      <div>
        <Subtitle>PASSO 1 DE 3</Subtitle>
        <TitleH3>Primeiros talentos</TitleH3>
        <Input
          title="Seu Nome"
          placeholder="Insira seu nome."
          type="text"
          icon={<FiUser className="icon" />}
          required
          name="firstName"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <Input
          title="Seu Sobrenome"
          placeholder="Insira seu sobrenome."
          type="text"
          icon={<FiUser className="icon" />}
          required
          name="lastName"
          value={surname}
          onChange={(e: any) => setSurname(e.target.value)}
        />

        <Input
          title="Seu E-mail"
          placeholder="Insira seu e-mail."
          type="email"
          icon={<FiMail className="icon" />}
          span="Não se preocupe. Não vamos fazer SPAM no seu e-mail ;-)"
          required
          name="email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setErrorEmail(false);
          }}
        />
        <Input
          title="Seu Whatsapp"
          placeholder="(99) 99999-9999"
          type="tel"
          icon={<BsWhatsapp className="icon" />}
          span="Nossa equipe vai entrar em contato com você pelo WhatsApp."
          required
          name="telephone"
          value={phone}
          onChange={(e: any) => setPhone(e.target.value)}
        />
        <Input
          title="Seu Linkedin*"
          placeholder="seu url"
          type="text"
          icon={<BsLinkedin className="icon" />}
          span="Nossa equipe vai avaliar seu perfil"
          required
          name="linkedin"
          value={linkedin}
          onChange={(e: any) => setlinkedin(e.target.value)}
        />
        <Button
          onClick={() => {
            if (errorEmail === true) {
              setError("Preencha o email corretamente");
            } else {
              if (!disableButton) {
                const newState = {
                  ...formData,
                  firstName: name,
                  lastName: surname,
                  email,
                  linkedin: linkedin,
                  telephone: phone
                };
                setFormData(newState);
              

                setPasso(2);
              } else {
                setError("Algum campo está faltando");
              }
            }
          }}
        >
          CONTINUAR
        </Button>

        {error !== "" ? (
          <span
            style={{
              color: "#8853FF",
              width: "100%",
              display: "inline-block",
              textAlign: "center"
            }}
          >
            {error}
          </span>
        ) : null}

        <ButtonReturn
          onClick={() => {
            setTalentos(false);
          }}
        >
          <BsArrowReturnLeft size={20} />
          <span>VOLTAR A PÁGINA INICIAL</span>
        </ButtonReturn>
      </div>
    );
  };

  const Passo2 = (props: any) => {
    const [atuation, setAtuation] = useState(formData.atuation);
    const [level, setLevel] = useState(formData.level);
    const [history, setHistory] = useState(formData.history);
    const [message, setMessage] = useState(formData.message);
    const [privacyForm, setPrivacyForm] = useState(formData.privacy);
    const [disableButton, setDisableButton] = useState(true);
    const [error, setError] = useState("");
    const [cardFile, setCardFile] = useState("");
    const [fileName, setFileName] = useState(formData.fileName);

    const handleUploadFile = (e: any) => {
      setCardFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    };

    useEffect(() => {
      if (atuation !== 0 && history !== "" && message !== "" && level !== 0) {
        setDisableButton(false);
      }
    }, [atuation, level, history, message]);

    return (
      <div {...props}>
        <Subtitle>PASSO 2 DE 3</Subtitle>
        <TitleH3>Fale sobre seu projeto</TitleH3>
        <SelectForm
          title="Área de Atuação"
          placeholder="Sua área de alocação"
          options={atuationSelect}
          onChange={(e: any) => setAtuation(e.value)}
          name="atuation"
          defaultValue={atuationSelect.find((e) => e.value === atuation)}
        />
        <SelectForm
          title="Senioridade"
          placeholder="Sua Senioridade"
          options={levelSelect}
          onChange={(e: any) => setLevel(e.value)}
          name="level"
          defaultValue={levelSelect.find((e) => e.value === level)}
        />
        <TextArea>
          <textarea
            title="Trajetória"
            placeholder="Descreva em poucas palavras sua trajetória profissional…"
            value={history}
            onChange={(e: any) => setHistory(e.target.value)}
            name="history"
          />
        </TextArea>
        <TextArea>
          <textarea
            title="Deixe uma Mensagem"
            placeholder="Deixe uma mensagem"
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            name="message"
          />
        </TextArea>
        <label className="form-label">
          {fileName}

          <input
            type="file"
            required
            onChange={handleUploadFile}
            accept="application/pdf"
            className="input-file"
          />
        </label>
        <span className="textSpan">
          Ao se registrar, você concorda com o processamento de seus dados
          pessoalmente pela DUXCOD3 conforme descrito na Política de
          Privacidade.
        </span>
        <div className="checkbox">
          <InputCheckbox
            type="checkbox"
            checked={privacyForm}
            onChange={(e) => setPrivacyForm(e.target.checked)}
            name="privacy"
          />
          <span>
            Li e concordo com a{" "}
            <label
              onClick={() => {
                
                setFormData({
                  email: formData.email,
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  telephone: formData.telephone,
                  privacy: privacyForm,
                  atuation: atuation,
                  linkedin: formData.linkedin,
                  level: level,
                  history: history,
                  message: message,
                  file: cardFile,
                  fileName: fileName
                });
                setOnTermos(true);
              }}
            >
              <strong
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Política de Privacidade
              </strong>
            </label>
          </span>
        </div>
        <br />

        <Button
          onClick={() => {
            if (!disableButton) {
              const newState = {
                ...formData,
                level: level,
                history: history,
                message: message,
                file: cardFile,
                atuation: atuation,
                
              };
              setFormData(newState);
              
              sendApiForm(newState);

              setPasso(3);
            } else {
              setError("Algum campo está faltando");
            }
          }}
        >
          CONTINUAR
        </Button>
        {error !== "" ? (
          <span
            style={{
              color: "#8853FF",
              width: "100%",
              display: "inline-block",
              textAlign: "center"
            }}
          >
            {error}
          </span>
        ) : null}
        <ButtonReturn
          onClick={() => {
            setFormData({
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
              telephone: formData.telephone,
              privacy: privacyForm,
              atuation: atuation,
              linkedin: formData.linkedin,
              level: level,
              history: history,
              message: message,
              file: cardFile,
              fileName: fileName
            });
            setPasso(1);
          }}
        >
          <BsArrowReturnLeft size={20} />
          <span>VOLTAR A PÁGINA INICIAL</span>
        </ButtonReturn>
      </div>
    );
  };

  const Passo3 = (props: any) => {
    return (
      <Passo3Container>
        <div className={"bg-gradient"}>
          <ExitButton
            onClick={() => {
              setPasso(1);
              setIsOpen(false);
            }}
          >
            <img src={ExitSvg} />
          </ExitButton>
          <div className="bg-white">
            <Subtitle>PASSO 3 DE 3</Subtitle>
            <img src={logo} alt="logo" />
            <TitleH3>
              <span className="text-center">
                Bem-vindo a DUXCOD3
                <br />
              </span>
            </TitleH3>
            <p className="resume">
              Em breve um de nossos consultores irá
              <br />
              entrar em contato com você.
            </p>
            <Subtitle>
              Mas fique a vontade para falar conosco nos canais a baixo:
            </Subtitle>
            <div className="buttons">
              <div>
                <ButtonReturn
                  href="mailto:comercial@duxcod3.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FiMail size={20} />
                  <span>ENVIAR UM E-MAIL</span>
                </ButtonReturn>
              </div>
              <div>
                <Button
                  href="https://api.whatsapp.com/send?phone=5531985217476&text=Olá.%20Vi%20o%20site%20da%20DUXCOD3,%20aguardo%20mais%20informações."
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsWhatsapp size={20} />
                  FALAR AGORA PELO WHATSAPP
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Passo3Container>
    );
  };

  return (
    <>
      <input
        type="checkbox"
        style={{ display: "none" }}
        className={onTalentos ? "onSidebarCadastro" : ""}
      />
      {passo !== 3 ? (
        <SidebarContainer>
          {passo === 1 ? <Passo1 /> : passo === 2 ? <Passo2 /> : null}
        </SidebarContainer>
      ) : (
        <Passo3 />
      )}
    </>
  );
};
function gtag(
  arg0: string,
  arg1: string,
  arg2: { send_to: string; event_callback: () => void }
) {
  throw new Error("Function not implemented.");
}
