import { ContactContainer, ContactBorder } from "./styles";

type props = {
  href?: any;
  title: string;
  text1?: string;
  text2?: string;
  onHover: any;
  children?: React.ReactNode;
  onClick?: any
};

export default function ContactButtons(props: props) {
  function mouseEnter() {
    props.onHover("url(#a)");
  }

  function mouseLeave() {
    props.onHover("white");
  }

  return (
    <ContactBorder>
      <ContactContainer
        href={props.href}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        target="_blank"
        rel="noreferrer"
        onClick={props.onClick}
      >
        {props.children}
        <div className="informationDiv">
          <h4>{props.title}</h4>
          <p>{props.text1}</p>
          <p>{props.text2}</p>
        </div>
      </ContactContainer>
    </ContactBorder>
  );
}
