import { Subtitle } from "../../../../components/elements/subtitle";
import { AcompanheContainer } from "./styles";
import { useState } from "react";

import { Link } from "react-router-dom";
import SocialButtons from "../../../../components/elements/SocialButtons";

import WhatsappIcon from "../../../../components/elements/icons/WhatsappIcon";
import LinkedinIcon from "../../../../components/elements/icons/LinkedinIcon";
import InstagramIcon from "../../../../components/elements/icons/InstagramIcon";
import FacebookIcon from "../../../../components/elements/icons/FacebookIcon";

export const Acompanhe = () => {
  const [facebookColor, setFacebookColor] = useState('url(#a)');
  const [instagramColor, setInstagramColor] = useState('url(#a)');
  const [linkedinColor, setLinkedinColor] = useState('url(#a)');
  const [whatsappColor, setWhatsappColor] = useState('url(#a)');
  return (
    <div className="container">
      <AcompanheContainer>
        <div className="container-bg">
          <Subtitle>
            <span className="acompanhe">Acompanhe nossas redes sociais:</span>
          </Subtitle>
          <div className="social-networks">
            <SocialButtons onHover={setFacebookColor} href={"https://www.facebook.com/duxcod3"}>
              <FacebookIcon color={facebookColor} />
            </SocialButtons>
            <SocialButtons onHover={setInstagramColor} href={"https://www.instagram.com/duxcod3/"}>
              <InstagramIcon color={instagramColor} />
            </SocialButtons>
            <SocialButtons onHover={setLinkedinColor} href={"https://www.linkedin.com/company/duxcod3/"}>
              <LinkedinIcon color={linkedinColor}/>
            </SocialButtons>
            <SocialButtons onHover={setWhatsappColor} href={"https://api.whatsapp.com/send?phone=5531985217476&text=Olá.%20Vi%20o%20site%20da%20ROCKE%20IT,%20aguardo%20mais%20informações."}>
              <WhatsappIcon color={whatsappColor} />
            </SocialButtons>
          </div>
        </div>
      </AcompanheContainer>
    </div>
  );
};
