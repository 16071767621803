import styled from 'styled-components'
import { fontsType } from '../../../styles/fonts'
import { Theme } from '../../../theme'

interface DarkProps {
	isDarkTheme?: boolean
}

export const LogoImage = styled.img`
	margin-left: 3rem;
	width: 20rem;
	height: auto;
	z-index: 101;

	@media (max-width: 767px) {
		margin-left: 0px;
		width: 18rem;
	}

	@media (max-width: 300px) {
		margin-left: 0px;
		width: 13rem;
	}
`

export const Nav = styled.div`
	margin-top: 10px;
	width: 100%;
  	display: flex;
  	flex-direction: column;
  	margin-top: 10px;

  	@media (max-width: 767px) {
		align-items: center;
	}
	
	.navbar-nav-item {
		align-items: center;
		justify-content: center;
		display: flex;
	}
	.navbar-nav-subItem {
		align-items: center;
		justify-content: flex-start;
		display: flex;
		position: relative;
	}

	.navbar-div {
		display: none;
	}

	.navbar-nav-subItem nav {
		display: none;
	}
	.navbar-nav-subItem:hover nav {
		display: flex;
	}
	.navbar-div {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		background-color: #ffffff;
		padding: 20px;
		width: 200px;
		position: absolute;
		top: 40px;
		left: 20%;
	}

	.navbar-brand {
		width: 30%;
	}
`

export const NavbarListMenu = styled.ul<DarkProps>`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	li {
		position: relative;
		margin-left: 10px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		a {
			color: ${(props) =>
				(props.isDarkTheme ? Theme.dark : Theme.light).text} !important;
			text-decoration: none;
			list-style-type: none;
			font-family: ${fontsType.rubikLight};
			position: relative;
		}
	}

	li::after {
		content: '';
		display: block;
		position: absolute;
		width: 0%;
		height: 3px;
		background: #6653ff;
		bottom: -8px;
		border-radius: 5px;
		transition: all 0.1s ease-in-out;
	}

	li:hover {
		a {
			font-family: ${fontsType.rubikBold};
		}
		&::after {
			background-color: #6653ff;
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
		}
	}

	.navbar-nav-subItem:hover {
		.nav-link-subItem {
			font-family: ${fontsType.rubikBold};
		}
		&::after {
			background-color: #6653ff;
			width: 80%;
			margin: auto 10%;
		}
	}
`

export const NavbarLoginContainer = styled.div<DarkProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0;
`
