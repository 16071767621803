import styled from "styled-components";

export const NossasTecnologiasContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 100px;
    margin-bottom: 70px;
    max-width: 50%;
  }
  @media (max-width: 414px) {
    img {
      max-width: 100%;
    }
    .title{
      display: flex ;
      align-items: center ;
      justify-content: center ;
      width: 100% ;
    }
  }
`;
