import { SocialMediaContainer } from "./styles";

type Props = {
  children?: React.ReactNode,
  onHover: any,
  href: any
};

export default function SocialButtons(props: Props) {
  
  function onMouseEnterChange(){
    props.onHover('white');
  }
  
  function onMouseLeaveChange(){
    props.onHover('url(#a)');
  }

  return (
    <>
      <SocialMediaContainer
        href={props.href}
        onMouseEnter={onMouseEnterChange}
        onMouseLeave={onMouseLeaveChange}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </SocialMediaContainer>
    </>
  );
}
