import styled from 'styled-components'
import { fontsType } from '../../../../styles/fonts'

export const Container = styled.div`
	margin: 0 0 150px;

	width: 100%;

	@media (min-width: 768px) {
		margin: 50px 0 100px;
	}
`

export const BorderGradient = styled.div`
	padding: 3px;
	border-radius: 15px;
	background: linear-gradient(180deg, #f653ff 0%, #6653ff 100%);
	position: relative;
	margin: 10px;

	@media (min-width: 768px) {
		width: 80%;
		margin: 50px;
	}
`

export const TextWrapper = styled.div`
	background: #060234;
	border-radius: 15px;
	padding: 30px 20px 50px;

	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));

		padding: 85px 55px;
	}
`

export const Content = styled.div`
	gap: 20px;

	color: #fff;
	text-align: center;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 768px) {
		grid-column: span 2 / span 2;
		text-align: start;
		justify-content: start;
		align-items: start;
	}
`

export const Title = styled.span`
	font-family: ${fontsType.ErbaumMedium};
	font-size: large;

	@media (min-width: 1220px) {
		font-size: x-large;
	}
`
export const Text = styled.div`
	@media (min-width: 1220px) {
		font-size: large;
	}
`

export const Image = styled.img`
	position: absolute;
	width: 450px;
	right: 0;
	bottom: -20px;

	transform: translateX(20%);

	@media (max-width: 1224px) {
		width: 350px;
	}

	@media (max-width: 768px) {
		position: static;
		width: min(80%, 360px);
		transform: translateX(0);
	}
`

export const ButtonWrapper = styled.a`
	all: unset;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	background: linear-gradient(180deg, #f653ff 0%, #7053ff 100%);
	border-radius: 4px;

	width: 70%;

	transition: all 0.3s ease-in-out;

	&:hover {
		transform: scale(0.99);
		color: #fff;

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}

	&:active {
		transform: scale(1.1);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}
`
