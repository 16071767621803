import { createContext } from "react"

type ContextType = {
	isDarkTheme: boolean;
	setIsDarkTheme: (isDarkTheme: boolean) => void;
	onTermos: boolean;
	setOnTermos: (onTermos: boolean) => void;
	onTalentos: boolean;
	setTalentos: (onTalentos: boolean) => void;
	onSidebarCadastroEmpresas: boolean;
	setOnSidebarCadastroEmpresas: (onSidebarCadastroEmpresas: boolean) => void;
};

const Context = createContext<ContextType>({} as ContextType)

export default Context
