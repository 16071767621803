import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";

interface Props {
  children: string | React.ReactNode;
  color?: "white" | "black";
}

export const Description = ({ children, color }: Props) => {
  return <P color={color}>{children}</P>;
};

const P = styled.div`
  font-family: ${fontsType.rubikLight};
  color: ${(props) => props.color || "#000"};
  margin: 0;
  font-size: 15px;
  margin-top: 20px;
`;
