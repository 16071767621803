import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	gap: 25px;

  margin-bottom: 100px;
`

export const CardsWrapper = styled.div`
  width: 100%;
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 25px;
  justify-items: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`
