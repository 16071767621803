import { useContext, useEffect } from "react";
import { Footer } from "../../components/components/footer";
import { Navbar } from "../../components/components/navbar";
import { FormTalentos } from "../../components/components/sidebarFormTalentos/sidebar";
import { FormEmpresas } from "../../components/components/sidebarFormEmpresas/sidebar";
import { ScrowTo } from "../../components/elements/scrowTo";
import Context from "../../context";
import { ImagnsLaterais } from "./bg";
import { Acompanhe } from "./sections/acompanhe";
import { ComoFazemos } from "./sections/comoFazemos";
import { Cultura } from "./sections/cultura";
import { EstouAqui } from "./sections/estouAqui";
import { GestaoEspecializada } from "./sections/gestaoEspecializada";
import { Implementacao } from "./sections/implementacao";
import { MelhoresDoMercado } from "./sections/melhoresDoMercado";
import { NossaComunidade } from "./sections/nossaComunidade";
import { NossasTecnologias } from "./sections/nossasTecnologias";
import { NovoProjeto } from "./sections/novoProjeto";
import { ParaSuaEmpresa } from "./sections/paraSuaEmpresa";
import { Parceiros } from "./sections/parceiros";
import { Planejamento } from "./sections/planejamento";
import { StylesContainer } from "./style";
import FloatWhatsapp from "../../components/elements/FloatWhatsapp";
import { RevoluçãoDigital } from "./sections/revoluçãoDigital";
import { ParaSueTalento } from "./sections/paraSeuTalento";
import { CardNovo } from "./sections/card";
import { EmpresaCard } from "./sections/empresa/index";
import { Termos } from "../../components/components/termos/index";
import Initial from "./sections/initial";
import WantTalent from "./sections/wantTalent";
import HowItWorks from "./sections/howItWorks";
import FormSection from "./sections/formSection";

export const Empresas = () => {
  
  const {
    onTermos,
    isDarkTheme,
    setIsDarkTheme,
    onTalentos,
    setTalentos,
    onSidebarCadastroEmpresas,
    setOnSidebarCadastroEmpresas
  } = useContext(Context);

  useEffect(() => {
    setIsDarkTheme(true);
  }, [isDarkTheme, setIsDarkTheme]);

  return (
    <StylesContainer>
      {onTermos && <Termos />}
      {/* <FloatWhatsapp /> */}
      <span id="teste2"></span>
      {/* <ImagnsLaterais /> */}
      <FormTalentos isOpen={onTalentos} setIsOpen={setTalentos} />
      {onTalentos && (
        <FormTalentos isOpen={onTalentos} setIsOpen={setTalentos} />
      )}
      {onSidebarCadastroEmpresas && (
        <FormEmpresas
          isOpenSidebar={onSidebarCadastroEmpresas}
          setIsOpenSidbar={setOnSidebarCadastroEmpresas}
        />
      )}
      <Navbar />
      <Initial />
      {/* <EstouAqui /> */}
      {/* <ComoFazemos /> */}
      {/* <ScrowTo color="white" href="#ParaSuaEmpresa" /> */}
      {/* <ParaSuaEmpresa /> */}
      <GestaoEspecializada />

      <WantTalent />
      <HowItWorks />
      {/* <NovoProjeto /> */}
      {/* <ScrowTo color="white" href="#paraSeuTalento" /> */}
      {/* <ParaSueTalento /> */}
      {/* <Implementacao /> */}
      {/* <Cultura /> */}
      {/* <CardNovo /> */}
      <MelhoresDoMercado />
      <FormSection />
      {/* <EmpresaCard /> */}
      {/* <ScrowTo color="white" href="#revoluçãoDigital" /> */}
      {/* <RevoluçãoDigital /> */}
      <NossasTecnologias />
      <Parceiros />
      {/* <NossaComunidade /> */}
      <Acompanhe />
      <Footer />
    </StylesContainer>
  );
};
