import styled from 'styled-components'
import { fontsType } from '../../../../styles/fonts'

export const GestaoEspecializadaContainer = styled.div`
	margin-top: 200px;
	display: flex;
	flex-direction: column;
`

export const Content = styled.div`
  padding: 60px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 100px;
  align-items: center;

  @media (max-width: 1120px) {
    gap: 10px;
    align-items: start;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 60px 20px;
    text-align: center;
  }
`

export const Image = styled.img`
  width: 100%;
`
export const RightSide = styled.div`
  color: white;
  font-size: large;

  @media (max-width: 768px) {
    font-size: medium;
  }
`
