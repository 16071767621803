import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .span {
    font-family: ${fontsType.rubikRegular};
    font-size: 14px;
    margin-top: 10px;
    color: #ccc;
  }

  .formSelect__control {
    border: 1.5px #f653ff solid;
    border-radius: 10px;
    max-width: 450px;
    height: 40px;
  }

  .formSelect__menu-list {
    position: relative;
    height: 110px;
    padding-top: 2px;
    padding-bottom: 3px;
  }

  .formSelect__menu {
    border-radius: 16px;
  }

  .formSelect__option {
    width: 98%;
    border-radius: 24px;
    font-size: 0.85rem;
    font-weight: 300;
    height: 50px;
    margin:5px;
    line-height: 1rem;
    display: flex;
    align-items: center;
  }

  .formSelect__option--is-selected {
    background-color: #5D4CE9;
  }

  .formSelect__option:hover {
    background-color: #ccc ;
    transition: 0.2s all ease-in-out;
  }
`

export const TitleH3 = styled.h3`
  font-size: 20px;
  font-family: ${fontsType.ErbaumBlack};
  margin-top: 10px;
  color: #fff;

  .asterisco {
    color: #f653ff; 
  }
`;
