import React from 'react'
import CardHowItWorks from '../../../../components/elements/CardHowItWorks'
import MainText from '../../../../components/elements/MainText'

import { Container, CardsWrapper } from './styles'

const HowItWorks = () => {
	const cards = [
		{
			id: 1,
			title: 'Busca',
			text: 'Nós buscamos ativamente profissionais talentosos em nossa ampla rede de contatos e fontes de recrutamento.',
		},
		{
			id: 2,
			title: 'Análise de currículo',
			text: 'Analisamos cuidadosamente os currículos de todos os candidatos em busca de habilidades, experiências e realizações relevantes para a posição.',
		},
		{
			id: 3,
			title: 'Seleção',
			text: 'Selecionamos os melhores candidatos com base em nossos critérios de seleção rigorosos, incluindo a avaliação de suas habilidades teóricas e comportamentais.',
		},
		{
			id: 4,
			title: 'Teste',
			text: 'Realizamos testes de habilidades para garantir que nossos candidatos atendam aos padrões de excelência e desempenho que nossos clientes esperam.',
		},
		{
			id: 5,
			title: 'Conexão',
			text: 'Facilitamos a conexão entre nossos clientes e os candidatos selecionados para garantir um processo de recrutamento tranquilo e bem-sucedido.',
		},
		{
			id: 6,
			title: 'Acompanhamento',
			text: 'Acompanhamos o desempenho dos profissionais selecionados para garantir que eles continuem a atender ás necessidades de nossos clientes e oferecemos suporte adicional sempre que necessário',
		},
	]

	return (
		<Container>
			<MainText
				firstText='Como'
				pinkText={'funciona'}
				secondText='?'
			/>
			<CardsWrapper>
				{cards.map((card) => (
					<CardHowItWorks
						key={card.id}
						title={card.title}
						text={card.text}
						number={`0${card.id}.`}
					/>
				))}
			</CardsWrapper>
		</Container>
	)
}

export default HowItWorks
