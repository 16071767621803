import { Page404 } from "../pages/page404";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Empresas } from "../pages/empresas";

export const Rota = () => {
  return (
		<Router>
			<Switch>
				<Route exact path="/login">
					<Redirect to="/index" />
				</Route>
				<Route exact path="/index" component={Empresas} />
				<Route path="/">
					<Redirect to="/index" />
				</Route>
				<Route path="/404" component={Page404} />
				<Route path="/*">
					<Redirect to="/404" />
				</Route>
			</Switch>
		</Router>
	);
};
