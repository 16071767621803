import styled from "styled-components";
import bgImage from "../../../assets/imagens/Background.svg";
import { fontsType } from "../../../styles/fonts";

export const TermosBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(246, 83, 255, 0.05);
  opacity: 1;
  backdrop-filter: blur(20px);
  background-image: url(${bgImage});
  background-size: 1200px;
  background-repeat: repeat;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BrGradient = styled.div`
  background: linear-gradient(to bottom, #f653ff 0%, #6653ff 100%);
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TermosBody = styled.div`
  background-color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 650px) {
    .buttons {
      flex-direction: column;
    }
  }
`;
export const TermosTitle = styled.div`
  font-size: 15px;
  font-family: ${fontsType.ErbaumBlack};
`;

export const TermosText = styled.div`
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  border: 2px solid #f653ff;
  width: 50vw;
  min-width: 100%;
  height: 20vw;
  overflow-y: scroll;

  @media (max-width: 768px) {
    min-height: 50vw;
  }
  @media (max-width: 768px) {
    min-height: 70vw;
  }
  @media (max-width: 550px) {
    min-height: 80vw;
  }
`;

export const Button = styled.button`
  font-family: ${fontsType.rubikBold};
  font-size: 12px;
  text-decoration: none;
  color: #fff;
  margin-top: 20px;
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  margin-left: 24px;
  border-radius: 10px;
  transition: 0.3s;
  background-image: linear-gradient(to bottom, #f653ff, #6653ff);
  width: 100%;
  span {
    margin-right: 40px;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  @media (max-width: 650px) {
    margin-left: 0;
  }
`;

export const ButtonReturn = styled.label`
  font-family: ${fontsType.rubikLight};
  font-size: 12px;
  text-decoration: none;
  color: #000;
  margin-top: 20px;
  background-color: transparent;
  border: 2px solid #f653ff;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  margin-right: 24px;
  border-radius: 10px;
  transition: 0.3s;
  background: #fff;
  width: 100%;
  cursor: pointer;
  span {
    margin-right: 40px;
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.color || "#000"};
  }

  @media (max-width: 650px) {
    margin-right: 0;
  }
`;
