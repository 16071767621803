import { useContext, useEffect, useState } from "react";
import { BsArrowReturnLeft, BsWhatsapp } from "react-icons/bs";
import { FiMail, FiUser } from "react-icons/fi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import logo from "../../../assets/iconeDuxcod3.png";
import Context from "../../../context";
import { Input } from "../../elements/Input";
import SelectForm from "../../elements/Select";
import ExitSvg from "../../../assets/imagens/exit.svg";

import {
  Button,
  ButtonReturn,
  InputCheckbox,
  Passo3Container,
  SidebarContainer,
  Subtitle,
  TitleH3,
  TextArea,
  ExitButton
} from "./styles";

interface Props {
  isOpenSidebar: boolean;
  setIsOpenSidbar: (value: boolean) => void;
}

interface Values {
  email: string;
  firstName: string;
  lastName: string;
  telephone: string;
  company: string;
  privacy: boolean;
}

export const FormEmpresas = ({ isOpenSidebar, setIsOpenSidbar }: Props) => {
  const [passo, setPasso] = useState(1);
  const [sindex, setTesteZindex] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    telephone: "",
    company: "",
    aboutProject: "",
    solutions: 0,
    privacy: false
  });
  const {
    setOnTermos,
    onSidebarCadastroEmpresas,
    setOnSidebarCadastroEmpresas
  } = useContext(Context);

  async function sendApiForm(data: any) {
    const parseData = {
      nome: data.firstName,
      sobrenome: data.lastName,
      email: data.email,
      telefone: data.telephone,
      usuarioEmpresa: {
        nomeEmpresa: data.company,
        solucao: data.solutions,
        descricao: data.aboutProject
      }
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(parseData)
    };

    const result = fetch(
      "https://backend.rockeit.com.br/api/Usuario/Empresa",
      requestOptions
    )
      .then((e) => e.json())
      

    return result;
  }

  useEffect(() => {
    if (isOpenSidebar) {
      setOnSidebarCadastroEmpresas(true);
    } else if (!isOpenSidebar) {
      setOnSidebarCadastroEmpresas(false);
    }
  }, [isOpenSidebar]);

  const SolutionsSelect = [
    { value: 1, label: "Desenvolvimento Software" },
    { value: 2, label: "Marketing" },
    { value: 3, label: "Talento" },
    { value: 4, label: "Design Thinking" }
  ];

  const [privacy, setPrivacy] = useState(false);

  const Passo1 = (props: any) => {
    const [name, setName] = useState(formData.firstName);
    const [surname, setSurname] = useState(formData.lastName);
    const [email, setEmail] = useState(formData.email);
    const [phone, setPhone] = useState(formData.telephone);
    const [enterprise, setEnterprise] = useState(formData.company);
    const [disableButton, setDisableButton] = useState(true);
    const [error, setError] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
      if (phone.length !== 15) {
        setPhone(phone.replace(/[^\d]/g, ""));
        if (phone.length === 11) {
          setPhone(phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"));
        }
      }
    }, [phone]);

    useEffect(() => {
      setName(name.replace(/[0-9]/g, ""));
      setSurname(surname.replace(/[0-9]/g, ""));
    }, [name, surname]);

    useEffect(() => {
      setSurname(surname.replace(/[0-9]/g, ""));
    }, [surname]);

    useEffect(() => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        setErrorEmail(true);
      }
    }, [email]);

    useEffect(() => {
      if (
        name !== "" &&
        surname !== "" &&
        errorEmail !== true &&
        phone !== "" &&
        enterprise !== ""
      ) {
        setDisableButton(false);
      }
    }, [name, surname, email, phone, enterprise]);

    return (
      <div>
        <Subtitle>PASSO 1 DE 3</Subtitle>
        <TitleH3>Primeiros empresa</TitleH3>
        <Input
          title="Seu Nome"
          placeholder="Insira seu nome."
          type="text"
          icon={<FiUser className="icon" />}
          required
          name="firstName"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <Input
          title="Seu Sobrenome"
          placeholder="Insira seu sobrenome."
          type="text"
          icon={<FiUser className="icon" />}
          required
          name="lastName"
          value={surname}
          onChange={(e: any) => setSurname(e.target.value)}
        />
        <Input
          title="Sua Empresa"
          placeholder="Insira o nome da sua empresa."
          type="text"
          icon={<HiOutlineOfficeBuilding className="icon" />}
          name="company"
          value={enterprise}
          onChange={(e: any) => setEnterprise(e.target.value)}
        />
        <Input
          title="Seu E-mail"
          placeholder="Insira seu e-mail."
          type="email"
          icon={<FiMail className="icon" />}
          span="Não se preocupe. Não vamos fazer SPAM no seu e-mail ;-)"
          required
          name="email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setErrorEmail(false);
          }}
        />
        <Input
          title="Seu Whatsapp"
          placeholder="(99) 99999-9999"
          type="tel"
          icon={<BsWhatsapp className="icon" />}
          span="Nossa equipe vai entrar em contato com você pelo WhatsApp."
          required
          name="telephone"
          value={phone}
          onChange={(e: any) => setPhone(e.target.value)}
        />
        <Button
          onClick={() => {
            if (errorEmail === true) {
              setError("Preencha o email corretamente");
            } else {
              if (!disableButton) {
                const newState = {
                  ...formData,
                  firstName: name,
                  lastName: surname,
                  email,
                  company: enterprise,
                  telephone: phone
                };
                setFormData(newState);
               
                setPasso(2);
              } else {
                setError("Algum campo está faltando");
              }
            }
          }}
        >
          CONTINUAR
        </Button>
        {error !== "" ? (
          <span
            style={{
              color: "#8853FF",
              width: "100%",
              display: "inline-block",
              textAlign: "center"
            }}
          >
            {error}
          </span>
        ) : null}
        <ButtonReturn
          onClick={() => {
            setOnSidebarCadastroEmpresas(false);
            setErrorEmail(false);
          }}
        >
          <BsArrowReturnLeft size={20} />
          <span>VOLTAR A PÁGINA INICIAL</span>
        </ButtonReturn>
      </div>
    );
  };

  const Passo2 = (props: any) => {
    const [solution, setSolution] = useState(formData.solutions);
    const [aboutProject, setAboutProject] = useState(formData.aboutProject);
    const [privacyForm, setPrivacyForm] = useState(formData.privacy);
    const [disableButton, setDisableButton] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
      if (solution !== 0 && aboutProject !== "" && privacyForm !== false) {
        setDisableButton(false);
      }
    }, [solution, aboutProject, privacyForm]);

    return (
      <div {...props}>
        <Subtitle>PASSO 2 DE 3</Subtitle>
        <TitleH3>Fale sobre seu projeto</TitleH3>
        <SelectForm
          title="Solução que procua"
          placeholder="Escolha uma solução"
          options={SolutionsSelect}
          onChange={(e: any) => setSolution(e.value)}
          name="solutions"
          defaultValue={SolutionsSelect.find((e)=> e.value === solution)}
        />
        <TextArea>
          <textarea
            title="Sobre seu projeto"
            placeholder="Descreva aqui o que precisa em seu projeto…"
            value={aboutProject}
            onChange={(e: any) => setAboutProject(e.target.value)}
            name="aboutProject"
          />
        </TextArea>
        <span style={{ color: "#ffffff", textAlign: "center" }}>
          Ao se registrar, você concorda com o processamento de seus dados
          pessoalmente pela DUXCOD3 conforme descrito na Política de
          Privacidade.
        </span>
        <div className="checkbox">
          <InputCheckbox
            type="checkbox"
            checked={privacyForm}
            onChange={(e) => setPrivacyForm(e.target.checked)}
            name="privacy"
          />
          <span>
            Li e concordo com a{" "}
            <label
              onClick={() => {
                setFormData({
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  email: formData.email,
                  company: formData.company,
                  telephone: formData.telephone,
                  solutions: solution,
                  aboutProject: aboutProject,
                  privacy: privacyForm
                });
                
                setOnTermos(true);
              }}
            >
              <strong
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Política de Privacidade
              </strong>
            </label>
          </span>
        </div>
        <br />

        <Button
          onClick={() => {
            if (!disableButton) {
              const newState = {
                ...formData,
                solutions: solution,
                privacyForm: privacy,
                aboutProject: aboutProject
              };
              setFormData(newState);
              sendApiForm(newState);
              setPasso(3);
            } else {
              setError("Algum campo está faltando");
            }
          }}
        >
          CONTINUAR
        </Button>
        {error !== "" ? (
          <span
            style={{
              color: "#8853FF",
              width: "100%",
              display: "inline-block",
              textAlign: "center"
            }}
          >
            {error}
          </span>
        ) : null}
        <ButtonReturn
          onClick={() => {
            setFormData({
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              company: formData.company,
              telephone: formData.telephone,
              solutions: solution,
              aboutProject: aboutProject,
              privacy: privacyForm
            });
            setPasso(1);
          }}
        >
          <BsArrowReturnLeft size={20} />
          <span>VOLTAR A PÁGINA INICIAL</span>
        </ButtonReturn>
      </div>
    );
  };

  const Passo3 = (props: any) => {
    return (
      <Passo3Container {...props} style={{ right: `${sindex}` + "vh" }}>
        <div className="bg-gradient">
          <ExitButton onClick={() => setPasso(1)}>
            <img src={ExitSvg} alt="Exit button" />
          </ExitButton>
          <div className="bg-white">
            <Subtitle>PASSO 3 DE 3</Subtitle>
            <img src={logo} alt="logo" />
            <TitleH3>
              <span className="text-center">
                Bem-vindo a DUXCOD3
                <br />
              </span>
            </TitleH3>
            <p className="resume">
              Em breve um de nossos consultores irá
              <br />
              entrar em contato com você.
            </p>
            <Subtitle>
              Mas fique a vontade para falar conosco nos canais a baixo:
            </Subtitle>
            <div className="buttons">
              <div onClick={() => setTesteZindex(-410)}>
                <ButtonReturn href="mailto:comercial@duxcod3.com">
                  <FiMail size={20} />
                  <span>ENVIAR UM E-MAIL</span>
                </ButtonReturn>
              </div>
              <div onClick={() => setTesteZindex(-410)}>
                <Button href="https://api.whatsapp.com/send?phone=5531985217476&text=Olá.%20Vi%20o%20site%20da%20DUXCOD3,%20aguardo%20mais%20informações.">
                  <BsWhatsapp size={20} />
                  FALAR AGORA PELO WHATSAPP
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Passo3Container>
    );
  };

  return (
    <>
      <input
        type="checkbox"
        style={{ display: "none" }}
        className={onSidebarCadastroEmpresas ? "onSidebarCadastro" : ""}
      />
      {passo !== 3 ? (
        <SidebarContainer>
          {passo === 1 ? <Passo1 /> : passo === 2 ? <Passo2 /> : null}
        </SidebarContainer>
      ) : (
        <Passo3 />
      )}
    </>
  );
};
