import React from 'react'

import {
	Container,
	TextWrapper,
	Title,
	Text,
	Image,
	BorderGradient,
	Content,
	ButtonWrapper,
} from './styles'

import Img from '../../../../assets/imagens/wantTalentImage.png'

const WantTalent = () => {
	return (
		<Container>
			<BorderGradient>
				<TextWrapper>
					<Content>
						<Title>O que a DUXCOD3 oferece?</Title>
						<Text>
							Todos sabemos da dificuldade que é encontrar um bom profissional
							no mercado, pensando nisso trouxemos uma forma onde nossos
							clientes poderão ter uma equipe de profissionais altamente
							qualificados, sem a necessidade de contratar diretamente esses
							profissionais, permitindo uma locação de talentos já escolhidos a
							dedo por nós e necessários para atender demandas específicas do
							seu projeto.
						</Text>
						<Text>
							Dessa forma te damos a capacidade de escalar sua equipe sem os
							perrengues e custos necessários para a contratação e treinamento
							de um novo funcionário, trazendo uma rede ampla de profissionais
							com habilidades especializadas e totalmente prontos para assumir o
							seu projeto.
						</Text>
						<Button />
					</Content>
					<Image src={Img} />
				</TextWrapper>
			</BorderGradient>
		</Container>
	)
}

const Button = () => {
  const handleClick = () => {
    const formComponent = document.getElementById("form");
    if (formComponent) {
      formComponent.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ButtonWrapper onClick={handleClick}>
      <Text>QUERO UM TALENTO</Text>
    </ButtonWrapper>
  );
};

export default WantTalent
