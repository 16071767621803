import styled from 'styled-components'
import { fontsType } from '../../../../styles/fonts'

import { FiUser } from 'react-icons/fi'
import { AiOutlineMail, AiOutlineWhatsApp } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'

import * as Select from '@radix-ui/react-select'
import * as Toast from '@radix-ui/react-toast'

interface TextAreaProps {
	textArea?: boolean
}

interface ToastErrorProps {
	toastError?: boolean
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;

	gap: 30px;

	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: start;
		padding: 0 60px;
	}
`

export const ImageSection = styled.div`
	width: 90%;

	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 20px;

	@media (min-width: 768px) {
		width: 50%;
	}
`
export const Image = styled.img`
	width: min(360px, 100%);
	/* width: 100%; */

	@media (min-width: 768px) {
		width: 100%;
	}

	@media (min-width: 1350px) {
		width: 618px;
	}

	@media (min-width: 1650px) {
		width: 750px;
	}
`

export const FormBorder = styled.div`
	margin: 0 10px;
	border-radius: 23px;

	padding: 4px;

	background: linear-gradient(180deg, #f853ff 0%, #6a53ff 100%);
`

export const Form = styled.form`
	border-radius: 23px;

	background: #060234;

	padding: 20px 12px;

	display: flex;
	flex-direction: column;

	color: #fff;

	gap: 40px;

	@media (min-width: 1230px) {
		padding: 40px 24px;
	}
`

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 10px;
`

export const Title = styled.div`
	font-family: ${fontsType.ErbaumMedium};
	font-size: 15px;

	text-align: center;
	color: #FFF;

	@media (min-width: 768px) {
		text-align: start;
	}

	@media (min-width: 1230px) {
		font-size: 24px;
	}
`

export const SubTitle = styled.div`
	font-family: ${fontsType.rubikRegular};
	font-size: 10px;

	@media (min-width: 1230px) {
		font-size: 18px;
	}
`
export const InputsSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;

	gap: 8px;
`

export const Label = styled.label`
	font-family: ${fontsType.ErbaumMedium};

	::after {
		content: '*';
		font-family: ${fontsType.rubikRegular};
		font-weight: bold;
		color: #f653ff;
	}
`

export const InputContainer = styled('input').attrs<TextAreaProps>(
	({ textArea }) => ({
		as: textArea ? 'textarea' : 'input',
	})
)<TextAreaProps>`
	background: #f3f4f5;
	border: 1px solid #f653ff;
	border-radius: 5px;
	padding: 12px 35px;

	width: 100%;
	height: ${(props) => (props.textArea ? '100px' : 'auto')};

	color: #6653ff;
	font-family: ${fontsType.rubikRegular};
	font-size: small;

	::placeholder {
		color: #6653ff;
	}
`

export const UserIcon = styled(FiUser)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	width: 16px;
	height: 16px;
	color: #f653ff;
`

export const EmailIcon = styled(AiOutlineMail)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	width: 16px;
	height: 16px;
	color: #f653ff;
`

export const WhatsAppIcon = styled(AiOutlineWhatsApp)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	width: 16px;
	height: 16px;
	color: #f653ff;
`

export const PencilIcon = styled(HiOutlinePencil)`
	position: absolute;
	top: 14px;
	left: 10px;
	width: 16px;
	height: 16px;
	color: #f653ff;
`

export const SelectRoot = styled(Select.Root)``
export const SelectTrigger = styled(Select.Trigger)`
	background: #f3f4f5;
	border: 1px solid #f653ff;
	border-radius: 5px;
	padding: 12px;

	display: flex;
	justify-content: space-between;
	color: #6653ff;
`
export const SelectValue = styled(Select.Value)``
export const SelectIcon = styled(Select.Icon)``
export const SelectPortal = styled(Select.Portal)``
export const SelectContent = styled(Select.Content)``
export const SelectViewport = styled(Select.Viewport)`
	padding: 24px 12px;
	border-radius: 16px;

	background: white;
`

export const SelectItem = styled(Select.Item)`
	padding: 12px;
	border-radius: 9999px;
	font-family: ${fontsType.rubikLight};
	color: #5d4ce9;

	transition: all 0.5s ease-in-out;
	outline: none;

	cursor: pointer;
	&:hover {
		background: #5d4ce9;
		color: white;
	}
`

export const SelectItemText = styled(Select.ItemText)``

export const SelectItemIndicator = styled(Select.ItemIndicator)``

export const SubmitButtonCom = styled.button`
	background: linear-gradient(180deg, #f653ff 0%, #7053ff 100%);
	border-radius: 9px;
	outline: none;
	border: none;

	padding: 20px;

	color: white;
	font-family: ${fontsType.rubikBold};

	display: flex;
	justify-content: space-between;

	transition: all 0.5s ease-in-out;

	&:hover {
		transform: scale(1.01);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}

	&:active {
		transform: scale(0.9);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}
`

export const ToastProvider = styled(Toast.Provider)``
export const ToastRoot = styled(Toast.Root)<ToastErrorProps>`
	all: unset;
	z-index: 999;
	text-align: center;
	padding: 2px 40px;
	background: #060234;
	border-radius: 4px;
	border: ${({ toastError }) =>
		toastError ? '2px solid red' : '2px solid rgb(0, 255, 0)'};
	color: ${({ toastError }) => (toastError ? 'red' : 'rgb(0, 255, 0)')};
	position: fixed;
	top: 10px;
	right: 50%;
	transform: translateX(50%);
`
export const ToastTitle = styled(Toast.Title)``
export const ToastClose = styled(Toast.Close)``
export const ToastViewport = styled(Toast.Viewport)``
