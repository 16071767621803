import { useContext } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import Logo from "../../../assets/logoDuxcod3Quadrada.png"
import Context from "../../../context";
import { Nav, NavbarListMenu, LogoImage } from "./styled";

interface LiProps {
  href: string;
  children: string;
}

export const Navbar = () => {
  const { isDarkTheme } = useContext(Context);

  const menuItens = [
   
    {
      href: "https://www.marketing.duxcod3.com/",
      children: "Marketing Digital"
    },
    { href: "https://www.ui.duxcod3.com/", children: "Design Thinking" },
    // { href: "https://www.talentos.duxcod3.com/", children: "Talentos" }
  ];

  const Li = ({ href, children }: LiProps) => {
    return (
      <li   className="nav-item">
        <a className="nav-link" href={href}  target="_blank"
          rel="noreferrer">
          {children}
        </a>
      </li>
    );
  };
 

  return (
    <Nav className="container" id="navbar">
      {/*<nav className="navbar navbar-expand-lg">*/}
        {/* <div className="navbar-brand navbar-img-container"> */}
          <a href='https://www.duxcod3.com' target="_blank"
          rel="noreferrer">

          <LogoImage src={Logo} alt="logo Rocke It"/>
          </a>
        {/* </div> */}

        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <BiMenuAltRight />
        </button> */}
        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <NavbarListMenu
            isDarkTheme={isDarkTheme}
            className="navbar-nav me-auto mb-2 mb-lg-0"
          >
            {menuItens.map((item, i) => (
              <Li key={i} href={item.href}>
                {item.children}
              </Li>
            ))}
           
          </NavbarListMenu>
        </div> 
      </nav>*/}
    </Nav>
  );
};
