export const Theme = {
  light: {
    text: "#1a1a1a",
    background: "#fff",
  },
  dark: {
    text: "#fafafa",
    background: 'radial-gradient(128.54% 128.54% at 50% 0%, #201B52 0%, #060234 100%)'

  },
};
