import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;

	.asterisco {
		color: #f653ff;
	}
	.optional {
		font-family: ${fontsType.rubikRegular};
		font-size: 14px;
		margin-left: 5px;
		color: #060234;
	}

	.span {
		font-family: ${fontsType.rubikRegular};
		font-size: 14px;
		margin-top: 10px;
		color: #ccc;
	}
`;

export const InputDiv = styled.div`
	border: 2px solid #f653ff;
	border-radius: 5px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	background-color: #fff;
	input {
		background-color: #fff;
		padding: 7px 0px;
		margin-left: 5px;
		border: none;
		flex: 1;
	}
	input::placeholder {
		color: #333;
	}
	input:focus {
		outline: none;
	}
	svg {
		color: #f653ff;
	}
`;

export const TitleH3 = styled.h3`
  font-size: 20px;
  font-family: ${fontsType.ErbaumBlack};
  margin-top: 10px;
`;
