import React from 'react'
import styled from 'styled-components'
import { fontsType } from '../../../styles/fonts'

interface CardHowItWorksProps {
	title: string
	text: string
	number: string
}

const CardHowItWorks = (props: CardHowItWorksProps) => {
	const { title, text, number } = props
	return (
		<Container>
			<TextWrapper>
				<Number>{number}</Number>
				<Title>{title}</Title>
				<Text>
					{text}
				</Text>
			</TextWrapper>
		</Container>
	)
}

export default CardHowItWorks

const TextWrapper = styled.div`
	background: #060234;
	border-radius: 15px;

	padding: 32px 2px;

	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: white;

	gap: 15px;

	@media (min-width: 768px) {
		gap: 30px;
	}
`

const Container = styled.div`
	background: linear-gradient(180deg, #f653ff 0%, #6653ff 100%);
	padding: 2px;
	width: 200px;
	height: 300px;
	border-radius: 15px;

	@media (min-width: 768px) {
		width: 300px;
    height: 400px;
	}
`

const Number = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 8px 6px;
	background: #251d64;
	border-radius: 6px;

	font-family: ${fontsType.ErbaumMedium};
	font-size: large;

	color: #6953ff;

	@media (min-width: 768px) {
		font-size: x-large;
		padding: 12px 8px;
	}
`
const Title = styled.span`
	font-family: ${fontsType.rubikBold};
	font-size: large;

	@media (min-width: 768px) {
		font-size: x-large;
	}
`

const Text = styled.span`
	font-family: ${fontsType.rubikLight};
	font-size: small;

	text-align: center;
`
