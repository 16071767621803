import styled from "styled-components";
import fundoRosaClaro from "../../../assets/imagens/Background.svg";
import { fontsType } from "../../../styles/fonts";

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  height: 100vh;
  width: 500px;
  margin-right: -505px;
  border-image: linear-gradient(to bottom, #f653ff, #6653ff) 1 100%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #221b5f 0%, #060235 100%) 0% 0%
    no-repeat padding-box;
  border-image-slice: 1;
  border-left-width: 5px;
  border-left-style: solid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 50px;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
  h3 {
    color: #fff;
  }

  .teste {
    input {
      min-height: 200px;
    }
  }

  #menu ~ & {
    display: none;
  }

  .onSidebarCadastro ~ & {
    margin-right: 0px;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: ${fontsType.rubikRegular};
    font-size: 14px;
    color: #aaa;
    margin-top: 10px;
  }

  @media (max-width: 414px) {
    width: 100%;
    height: 100% ;
    .icon{
      font-size: 20px !important ;
    }
  }
  @media (max-width: 280px) {
    width: 95%;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  .asterisco {
    color: #f653ff;
  }
  .optional {
    font-family: ${fontsType.rubikRegular};
    font-size: 14px;
    margin-left: 5px;
    color: #fff;
  }

  .span {
    font-family: ${fontsType.rubikRegular};
    font-size: 14px;
    margin-top: 10px;
    color: #ccc;
  }
`;

export const InputDiv = styled.div`
  border: 2px solid #f653ff;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  input {
    padding: 7px 0px;
    margin-left: 5px;
    border: none;
    flex: 1;
  }
  input::placeholder {
    color: #ccc;
  }
  input:focus {
    outline: none;
  }
  svg {
    color: #f653ff;
  }
`;

export const TitleH3 = styled.h3`
  font-size: 20px;
  font-family: ${fontsType.ErbaumBlack};
  margin-top: 10px;
`;

export const Subtitle = styled.div`
  font-family: ${fontsType.rubikRegular};
  color: #fff;
  margin: 0;
  font-size: 13px;
`;

export const Button = styled.a`
  font-family: ${fontsType.rubikBold};
  font-size: 12px;
  text-decoration: none;
  color: #fff;
  margin-top: 20px;
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  border-radius: 10px;
  transition: 0.3s;
  background-image: linear-gradient(to bottom, #f653ff, #6653ff);
  width: 100%;
  span {
    margin-right: 40px;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const ButtonReturn = styled.a`
  font-family: ${fontsType.rubikBold};
  font-size: 12px;
  text-decoration: none;
  color: #fff;
  margin-top: 20px;
  background-color: transparent;
  border: 2px solid #f653ff;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  border-radius: 10px;
  transition: 0.3s;
  width: 100%;
  cursor: pointer;
  span {
    margin-right: 40px;
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.color || "#ccc"};
  }
`;

export const InputCheckbox = styled.input`
  border: none;
  box-shadow: 0px 0px 3px #f653ff;
  margin-right: 10px;
`;

export const Passo3Container = styled.div`
  position: fixed;
  //z-index: 5;
  height: 100%;
  width: 100%;
  top: 0;
  //right: -110vw;
  background-color: #412c6bf7;
  background-image: url(${fundoRosaClaro});
  background-repeat: repeat;
  background-size: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  .onSidebarCadastro ~ & {
    margin-right: 0px;
  }

  .bg-gradient {
    background-image: linear-gradient(to bottom, #f653ff, #6653ff) !important;
    padding: 5px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    .bg-white {
      padding: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: transparent
        radial-gradient(closest-side at 50% 50%, #221b5f 0%, #060235 100%) 0% 0%
        no-repeat padding-box !important;
    }
  }

  .bg-white img {
    width: 150px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .text-center {
    display: inline-flex;
    align-items: center;
  }
  .resume {
    font-family: ${fontsType.rubikBold};
    font-size: 16px;
    margin: 20px 0;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 550px;
    & > button:first-child {
      margin-right: 10px;
    }
  }
  @media (max-width: 660px) {
    .buttons {
      flex-direction: column;
      min-width: 90%;
    }
  }
  @media (max-width: 540px) {
    .buttons {
      flex-direction: column;
      min-width: 80%;
    }
  }
  @media (max-width: 414px) {
    .buttons {
      flex-direction: column;
      min-width: 50%;
    }
	.bg-white img {
    width: 100px;
   
  }
  }
  @media (max-width: 280px) {
    .buttons {
      flex-direction: column;
      min-width: 70%;
    }
	.bg-white img {
    width: 80px;
   
  }
  }
`;

export const TextArea = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  textarea {
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 10px;
    border: #f653ff 1px solid;
    color: #000000dd;
    resize: none;
  }
`;

export const ExitButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 50px;
  background-color: #ffffff33;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;

  img {
    height: 80%;
    width: 80%;
  }
`;
