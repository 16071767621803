import userExperience from "../../../../assets/imagens/alocacao.svg";
import quemSomos from "../../../../assets/imagens/quemSomos.png";
import { ButtonSection } from "../../../../components/elements/buttonSection";
import { Description } from "../../../../components/elements/description";
import { Subtitle } from "../../../../components/elements/subtitle";
import { Title } from "../../../../components/elements/title";
import { MelhoresDoMercadoContainer } from "./style";

export const MelhoresDoMercado = () => {
  return (
    <MelhoresDoMercadoContainer id="melhores" className="container">
      <Title color="white" size={45}>
        <p className='description'>
          <span className="text">Acreditamos</span>
          <br />  no potencial das pessoas
        </p>
      </Title>

      <Description color="white">
        <p className="textDescription">
          E sabemos que o resultado vem de uma soma: conhecimento + tecnologia.
          Pensando nisso que unimos pessoas e processos da maneira mais
          eficiente possível.
        </p>
      </Description>
    </MelhoresDoMercadoContainer>
  );
};
