import styled from "styled-components"
import { fontsType } from "../../../styles/fonts"

interface Props {
  children: string | React.ReactNode
  color?: "white" | "black"
  size?: 25 | 35 | 45
}

export const Title = ({ children, color, size = 25 }: Props) => {
  return (
    <H1 color={color} size={size}>
      {children}
    </H1>
  )
}

interface H1Props {
  color?: string
  size: 25 | 35 | 45
}

const H1 = styled.h2<H1Props>`
  font-family: ${fontsType.ErbaumMedium};
  color: ${props => props.color || "#000"};
  margin: 0;
  font-size: ${props => props.size}px;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: ${props => props.size * 0.8}px;
  }
`
