import styled from "styled-components";
import { fontsType } from "../../../../styles/fonts";

export const MelhoresDoMercadoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 20px;

.description{
  width: 100% ;
  text-align: center ;
}
  .text{
    color: #F153FF;
  }
  .textDescription{
    font-size: 18px ;
    text-align: center ;
  }
  .button {
    display: flex;
  }
  .button a {
    flex: 1;
  }

  .right {
    width: 70%;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .right p {
    width: 51%;
    float: right;
    margin-bottom: 30px;
    text-align: left;
  }
  .right p:first-child {
    margin-top: 40px;
  }

  .left {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    float: left;
    position: absolute;
    left: 0;
    top: 20px;
  }

  .left img {
    float: left;
  }

  .left .user-experience {
    width: 280px;
    height: auto;
    z-index: 1;
  }
  .left .quem-somos {
    width: 550px;
    margin-top: -80px;
  }
  @media (max-width: 1400px) {
    .right {
      width: 60%;
    }
    .right p {
      width: 80%;
    }
    .left .quem-somos {
      width: 540px;
      margin-top: -50px;
    }
  }
  @media (max-width: 1200px) {
    .left .quem-somos {
      width: 400px;
      margin-top: -40px;
    }
    .left .user-experience {
      width: 300px;
    }
  }
  @media (max-width: 992px) {
    .left .user-experience {
      width: 260px;
      margin-top: 100px;
    }
    .left .quem-somos {
      width: 350px;
      margin-top: -20px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    .right,
    .left {
      width: 100%;
    }
    .right p {
      width: 100%;
    }
    .left {
      position: relative;
      margin-bottom: 40px;
    }
    .left .quem-somos {
      width: 100%;
      margin-top: -100px;
    }
    .left .user-experience {
      width: 300px;
      margin-top: -50px;
    }
  }
  @media (max-width: 576px) {
    .right {
      padding: 0 20px;
    }
    .right p {
      text-align: justify;
    }
    .left .quem-somos {
      width: 80%;
      margin: -70px auto 30px auto;
    }
  }
  @media (max-width: 500px) {
    .left .quem-somos {
      width: 100%;
    }
  }
`;

export const CardContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(to bottom, #f653ff, #6653ff);
  padding: 20px 20px;
  color: #fff;
  border-radius: 10px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    padding: 0 10px;
    font-family: ${fontsType.rubikRegular};
    span:first-child {
      font-size: 30px;
    }
    span:last-child {
      font-size: 10px;
    }
  }
  div:nth-child(2) {
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    padding: 0 40px;
    display: flex;
  }

  @media (max-width: 1200px) {
    padding: 15px;
    div {
      margin: 0 10px;
    }
  }
  @media (max-width: 500px) {
    padding: 10px;
    div:nth-child(2) {
      padding: 0 20px;
      display: flex;
    }
  }
  @media (max-width: 425px) {
    padding: 5px;
    div:nth-child(2) {
      padding: 0 10px;
      display: flex;
    }
  }
`;
