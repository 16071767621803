import styled from "styled-components";

export const ParceirosContainer = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    letter-spacing: 3px;
  }

  .companies {
    max-width: 600px;
    margin-top: 70px;
    margin-bottom: 100px;
  }
  .companies:nth-child(2) {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    .companies {
      max-width: 500px;
    }
  }
  @media (max-width: 582px) {
    margin-bottom: 60px;
    .companies {
      max-width: 94%;
    }
  }
  @media (max-width: 450px) {
    margin-bottom: 60px;
    padding: 20px;
   
  }

`;
